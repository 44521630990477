<template>
    <div id="saasList1" class="saas-list">
        <div class="saas-list-content" :class="tableConfig.footerShow ? 'saas-list-content-short' : 'saas-list-content-long'">
            <el-table
                v-loading="tableConfig.loading"
                :size="tableConfig.size ? tableConfig.size : 'medium'"
                element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.1)"
                ref="singleTable"
                class="saas-list-table"
                :data="tableConfig.tableData"
                :row-key="getRowKeys"
                :empty-text="emptyText"
                height="100%"
                highlight-current-row
                :header-cell-style="tableConfig.headerCellStyle"
                :cell-style="tableConfig.cellStyle"
                :show-overflow-tooltip="false"
                @current-change="handleTableCurrentChange"
                @row-click="clickRow"
                @cell-click="cellClick"
                @selection-change="selectionChange"
                :border="isResizable"
                @header-dragend="
                    (newWidth, oldWidth, column, event) => {
                        handleDrag(newWidth, oldWidth, column, event, tableConfig.tableHeader);
                    }
                ">
                <el-table-column v-if="tableConfig.checkbox" type="selection" :width="$utils.setFontSize(40)"></el-table-column>
                <el-table-column type="index" :width="$utils.setFontSize(60)" label="序号" prop="index" align="center" :fixed="currentPageName == 'realtimeMonitor' ? 'left' : false">
                    <template slot-scope="scope">
                        <div>
                            {{ table_index(scope.$index) }}
                        </div>
                    </template>
                </el-table-column>

                <!-- :fixed="item.label == '操作' ? 'right' : currentPageName == 'realtimeMonitor' && (item.prop == 'v6' || item.prop == 'v2') ? 'left':false" -->
                <el-table-column v-if="item.display != 'none'" v-for="(item, index) in tableConfig.tableHeader" :align="item.label == '操作' || item.label == '证据' || item.isCenter ? 'center' : 'left'" :prop="item.prop" :key="item.label + index" :label="item.label" :width="item.width" :min-width="item.minWidth" :class-name="item.className" :fixed="item.label == '操作' ? 'right' : item.fixed ? item.fixed : false">
                    <template v-if="item.children">
                        <el-table-column v-for="(v, i) in item.children" align="left" :key="v.label + i" :label="v.label" :width="v.width" :min-width="v.minWidth" :class-name="v.className" :fixed="false">
                            <template v-slot="scope">
                                <div v-if="v.tooltip" v-html="v.render(scope.row)"></div>
                                <el-tooltip v-else-if="v.render" class="item" :open-delay="250" :content="v.render(scope.row) && v.render(scope.row).toString()" placement="top">
                                    <div v-html="v.render(scope.row)"></div>
                                </el-tooltip>
                                <el-tooltip v-else class="item" :open-delay="250" :content="scope.row[v.prop]" placement="top">
                                    <div>{{ scope.row[v.prop] }}</div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </template>
                    <template v-slot="scope" v-if="item.label == '操作' || item.isType === 'evidence'">
                        <template v-if="item.option" v-for="itm in item.option">
                            <el-progress v-if="itm.name == 'downloadStatus' && scope.row.progress > 0 && scope.row.progress < 100" :text-inside="true" class="progress-item-option" :percentage="scope.row.progress"></el-progress>

                            <el-button
                                type="text"
                                v-else-if="itm.render ? itm.render(scope.row, scope.$index, scope.column) : itm.label"
                                class="colorComBtn pointer"
                                :class="[itm.name, itm.name + scope.row.fileId]"
                                :disabled="itm.disabled || (itm.isBtnHtml && scope.row.taskStatus == 0) || (itm.isType == 'feedBack' && scope.row.status == 2) || ((itm.name == 'video-play-btn' || itm.name == 'uploadStatus') && scope.row.fileSize === 0)"
                                v-has="itm.auth"
                                @click.stop="handleClick(itm.handler, scope.row)"
                                :style="itm.disabled ? 'color: #ddd' : ''">
                                {{ itm.render ? itm.render(scope.row, scope.$index, scope.column) : itm.label }}
                            </el-button>
                        </template>
                        <el-popover ref="moreIcon" placement="bottom" :trigger="item.trigger ? item.trigger : 'hover'" popper-class="el-popper-more" v-if="item.more && item.more.length">
                            <ul class="moreOptions" ref="moreOptions">
                                <template v-for="(itemMore, mIndex) in item.more">
                                    <li :key="mIndex + 'more'" v-has="itemMore.auth" @click.stop="handleClick(itemMore.handler, scope.row)" v-if="itemMore.render ? itemMore.render(scope.row) : itemMore.label">
                                        {{ itemMore.render ? itemMore.render(scope.row) : itemMore.label }}
                                    </li>
                                </template>
                            </ul>
                            <div class="more-icon" slot="reference" v-show="isVisibleMore[scope.$index]" @click.stop="handleMoreClick(item.more, scope.row)">
                                <span class="el-icon-more"></span>
                            </div>
                        </el-popover>
                    </template>
                    <template v-slot="scope" v-else-if="item.prop == 'terminalShow'">
                        <span v-if="scope.row.isBtn" class="iconfont iconziyuan2 pointer" v-has="item.auth" @click.stop="handleClick(item.handler, scope.row)"></span>
                    </template>
                    <template v-else-if="item.prop === 'photoShow'" v-slot="scope">
                        <div v-if="scope.row.attachReceiveCount">
                            <span>{{ scope.row.attachReceiveCount + '/' + scope.row.attachTotalCount }}</span>
                            <span style="padding-left: 0.6rem" class="iconfont icontupian" :class="scope.row.attachReceiveCount ? 'photo-show' : ''" @click.stop="handleClick('photoShow', scope.row)"></span>
                        </div>
                        <div v-else @click.stop="handleClick('photoShow', scope.row)">--</div>
                    </template>
                    <template v-else-if="item.prop === 'phoneStatus'" v-slot="scope">
                        <div v-if="scope.row.phoneStatus" :class="scope.row.phoneStatus ? 'photo-show' : ''" @click.stop="handleClick('phoneStatus', scope.row)">电话</div>
                        <div v-else>--</div>
                    </template>
                    <template v-slot="scope" v-else>
                        <template v-if="scope.row[item.prop] === '' && !item.render">
                            <span>--</span>
                        </template>
                        <!-- 设置不需要显示tooltip，配置项可以设置为 tooltip:false-->
                        <template v-else-if="item.tooltip">
                            <!-- 如果值为空，就直接显示 ，也不用el-tooltip功能 -->
                            <template v-if="scope.row[item.prop] === '' && !item.render">
                                <span>--</span>
                            </template>
                            <!-- 如果值不为空 -->
                            <template v-else>
                                <el-progress v-if="item.prop == 'uploadStatus' && scope.row.uploadStatus == '0' && scope.row.progress >= 0 && scope.row.progress <= 100" :text-inside="true" class="progress-item-row" :percentage="scope.row.progress"></el-progress>
                                <el-tooltip v-else-if="item.render" :open-delay="250" class="item" :content="item.render(scope.row) && item.render(scope.row).toString()" placement="top">
                                    <!-- tooltipDefine 允许自定义tooltipContent-->
                                    <div slot="content" v-if="scope.row.tooltipContent && item.tooltipDefine">
                                        {{ scope.row.tooltipContent }}
                                    </div>
                                    <div v-if="item.isClick && scope.row[item.prop] != item.triggerCondition && scope.row[item.prop] != '--'" v-html="item.render(scope.row)" class="table-click" @click.stop="handleClick(item.prop, scope.row)"></div>
                                    <div v-else v-html="item.render(scope.row)"></div>
                                </el-tooltip>
                                <el-tooltip v-else class="item" :open-delay="250" :content="scope.row[item.prop]" placement="top">
                                    <div>{{ scope.row[item.prop] }}</div>
                                </el-tooltip>
                            </template>
                        </template>
                        <!-- 设置不需要显示tooltip，配置项可以设置为 tooltip:false-->
                        <template v-else>
                            <div v-if="item.render" v-html="item.render(scope.row)"></div>
                            <span v-else>{{ scope.row[item.prop] }}</span>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column type="empty" prop="empty" :min-width="1"></el-table-column>
                <div slot="empty" class="empty">
                    <!-- <img src="../../assets/images/empty.jpg"/> -->
                    <span class="txt">{{ tableConfig.emptyText || '暂查不到记录' }}</span>
                </div>
            </el-table>
        </div>
        <div v-if="tableConfig.footerShow" class="saas-list-footer">
            <div v-if="tableConfig.selectShow" class="saas-list-selectedNum">
                已选
                <span>{{ selectedNum }}</span>
                条
            </div>
            <div class="saas-list-total">共{{ tableConfig.total }}条</div>
            <div class="saas-list-pageination">
                <el-pagination background :current-page.sync="selfCurrentPage" :page-sizes="pageSizes || [10, 20, 30, 40]" :page-size="pageSize" layout="sizes, prev, pager, next, jumper" :total="tableConfig.total" @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SAASTable',
    props: ['currentPage', 'tableConfig', 'pageIndex', 'pageSize', 'clicked', 'pageSizes', 'tableName', 'isResizable', 'currentPageName'],
    data() {
        return {
            currentRow: '',
            selfCurrentPage: 1,
            getRowKeys(row) {
                return row.id;
            },
            emptyText: '',
            selection: [],
            isInitial: true,
            selectedNum: 0,
            isVisibleMore: Array(100).fill(false)
        };
    },
    computed: {
        showHideCol() {
            return this.$store.state.showHideCol;
        }
    },
    watch: {
        // "tableConfig.tableData"(val) {
        //     this.$refs.singleTable.setCurrentRow();
        //     this.currentRow = "";
        //     if (val) {
        //         if (val.length == 0) {
        //             this.emptyText = "暂无数据";
        //         }
        //     } else {
        //         this.emptyText = "暂无数据";
        //     }
        // },
        pageIndex: {
            handler(newVal) {
                this.selfCurrentPage = newVal;
            }
        }
        // currentPage: {
        //     handler(newVal) {
        //         this.selfCurrentPage = newVal;
        //     }
        // },
    },
    created() {
        //this.rowClick = this.needRowClick?;
        this.emptyText = this.tableConfig.emptyText || '暂无数据';
    },

    beforeUpdate() {
        this.$nextTick(() => {
            this.$refs['singleTable']?.doLayout();
            //this.isVisibleMore = (this.$refs.moreOptions && this.$refs.moreOptions[0].childNodes.length)>0?true:false;
        });
    },
    updated() {
        // 页面中本来是20条数据，应该是20个，会取出来40个，是表格渲染的问题
        const moreDom = document.querySelectorAll('.moreOptions');
        if (moreDom.length > 0) {
            const moreDomLength = document.querySelectorAll('.moreOptions').length / 2;
            for (let i = 0; i < moreDomLength; i++) {
                // 根据.moreOptions是否有选项，控制“更多”是否显示
                this.$set(this.isVisibleMore, i, moreDom[i].innerText ? true : false);
            }
        }
    },
    methods: {
        handleDrag(newWidth, oldWidth, column, event, tableHeader) {
            tableHeader.forEach(item => {
                if (item.label == column.label) {
                    item.width = newWidth;
                }
            });
            this.showHideCol[this.currentPageName] = tableHeader;
            this.$store.commit('setShowHideCol', this.showHideCol);
            localStorage.setItem(this.currentPageName, JSON.stringify(tableHeader));
        },
        setTime(time) {
            if (typeof time == 'number') {
                return time;
            }
            if (time.indexOf('000+0000') === -1) {
                time = time.replace(/\-/g, '/');
                return Number(new Date(time)) + 28800000;
            }
            time = time.replace(/\.000\+0000/g, '');
            const date = Number(new Date(time)) + 28800000;
            return date;
        },
        table_index(index) {
            //this.pageIndex = this.pageIndex || 1;
            this.pageSize = this.pageSize || 10;
            return (this.pageIndex - 1) * this.pageSize + index + 1 + '';
        },
        handleClick(handler, value) {
            this.$emit('handleTableClick', handler, value);
        },
        handleSizeChange(val) {
            this.$emit('handleTableClick', 'sizeClick', val, this.tableName);
        },
        handleCurrentChange(val) {
            this.$refs.singleTable.setCurrentRow();
            this.$refs.singleTable.clearSelection();
            this.currentRow = null;
            this.$emit('handleTableClick', 'currentClick', val, this.tableName);
        },
        clickRow(row) {
            this.$refs.singleTable.toggleRowSelection(row);
            this.currentRow = row;
            this.$emit('handleTableClick', 'rowClick', row, this.tableName);
        },
        handleTableCurrentChange(val) {
            this.currentRow = val;
            this.$emit('update:clicked', val != null);
        },
        selectionChange(selection) {
            this.selectedNum = selection.length;
            this.selection = this.$refs.singleTable.selection;
            this.$emit('selectionChange', this.selection);
        },
        getSelection() {
            return this.$refs.singleTable.selection;
        },
        handleMoreClick(more, value) {
            // 防止点击“更多”向上冒泡
        },
        cellClick(row, column, cell, event) {
            this.$emit('handleTableClick', 'cellClick', row, column);
        }
    }
};
</script>

<style lang="less">
.baisejianyue .saas-list-table th.el-table__cell,
.tiankongzhilan .saas-list-table th.el-table__cell,
.baisejianyue .saas-list-tree-table th.el-table__cell,
.tiankongzhilan .saas-list-tree-table th.el-table__cell {
    border-bottom: 0.05rem solid #c1c9da !important;
}

.table-nav {
    height: 50px;
    font-size: 16px;
    padding: 0 1rem;
    line-height: 50px;

    .hint {
        font-size: 12px;
        color: red;
        line-height: 24px;
    }

    & > div:first-child {
        float: left;
    }

    .table-nav-btns {
        float: right;
        display: flex;
        align-items: center;
        height: 100%;

        .is-disabled {
            background: #a0cfff;
        }

        .is-disabled:hover {
            background: #a0cfff;
        }
    }

    .table-nav-btn {
        padding: 0 15px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin-left: 10px;
        font-size: 13px;
        border: none;
        background: #306deb;

        &:hover {
            background: #2064d1;
        }
    }
}

.saas-list {
    height: 100%;
    display: flex;
    flex-direction: column;

    .more-icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #004693;
        line-height: 20px;
        margin-left: 8px;
        cursor: pointer;

        &:hover {
            border-color: #1f55f7;
            color: #1f55f7;
        }
    }
}

.saas-list-content {
    overflow: auto;
    position: relative;
    flex: 1;
}

.saas-list-content-short1 {
    height: calc(100% - 50px);
}

.saas-list-content-long1 {
    height: 100%;
}

.saas-list-table {
    width: 100%;
    position: absolute;
    max-width: none;

    &.el-table--border .el-table__cell {
        border-right-color: #c1c9da;
    }

    .el-table-column--selection .cell {
        padding-right: 0.4rem;
        padding-left: 0.4rem;
    }

    .el-dialog__body {
        width: 100% !important;
        table-layout: fixed !important;
    }

    .el-button {
        padding: 0 2px;
        color: #409eff;

        &:hover {
            background: none !important;
        }
    }

    .el-table__header-wrapper {
        height: 48px;
    }

    .el-table__body-wrapper {
        height: calc(100% - 48px);

        .el-table__empty-text {
            width: 100%;
        }
    }

    .el-table__fixed-right {
        height: calc(100% - 8px) !important;
    }

    .photo-show {
        color: #409eff;
        cursor: pointer;
    }

    .table-click {
        color: #409eff;
        cursor: pointer;
    }

    .progress-item-row {
        width: 60%;
        display: inline-block;

        .el-progress-bar__outer {
            height: 10px !important;
            background-color: #004693 !important;

            .el-progress-bar__inner {
                line-height: 10px;
            }
        }
    }

    .progress-item-option {
        margin-left: 20px;
        width: 60%;
        display: inline-block;

        .el-progress-bar__outer {
            height: 10px !important;
            background-color: #004693 !important;

            .el-progress-bar__inner {
                line-height: 10px;
            }
        }
    }
}

.el-table__row > td > div > div {
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}

.saas-list-footer {
    width: 100%;
    height: 50px;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-top: 1px;
    padding: 10px 20px 10px 0;
}

.el-table__header,
.el-table__body {
    width: 100%;
}

.el-table td,
.el-table th.is-leaf {
    border-bottom: none;
}

.el-table--border::after,
.el-table--group::after,
.el-table::before {
    background: transparent;
}

.saas-list-selectedNum {
    margin-left: 10px;
    opacity: 0.8;

    span {
        opacity: 1;
        font-weight: bold;
    }
}

.saas-list-total {
    opacity: 0.8;
    margin-left: auto;
}

.el-table--border th,
.el-table__fixed-right-patch {
    /*border-bottom: none !important;*/
    background: transparent;
}

.el-table__fixed-header-wrapper {
    border-right: none !important;
}
</style>
